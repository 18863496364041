import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    accessToken: null,
    user: null,
    apiUrl: process.env.VUE_APP_API_URL,
    isDeviceRegistered: false,
  },
  getters: {
    user(state) {
      return state.user;
    },
    accessToken(state) {
      return state.accessToken;
    },
  },
  mutations: {
    USER_LOGIN(state, data) {
      state.accessToken = data.accessToken;
      state.user = data.user;
    },
    UPDATE_USER(state, data) {
      state.user.name = data.name;
      state.user.email = data.email;
      state.user.avatar = data.avatar;
    },
    LOGOUT(state) {
      state.accessToken = null;
      state.user = null;
      location.reload();
      location.herf = "/home";
    },
    SET_DEVICE_REGISTRATION(state, isRegistered) {
      state.isDeviceRegistered = isRegistered;
    },
  },
  actions: {},
  modules: {},
  plugins: [createPersistedState()],
});
