import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import BootstrapVue from "bootstrap-vue/dist/bootstrap-vue.esm";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "@/assets/styles/style.scss";
import ApiService from "./services/ApiService";
import ToggleButton from "vue-js-toggle-button";
import VueMeta from "vue-meta";
import { pushApi } from "@/pushApi";
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";

window.pushApi = pushApi;
Vue.use(VueMeta);
Vue.use(ToggleButton);
Vue.use(BootstrapVue);
Vue.component("vue-phone-number-input", VuePhoneNumberInput);

Vue.config.productionTip = false;
Vue.prototype.$http = ApiService;

new Vue({
  router,
  store,
  render: function (h) {
    return h(App);
  },
}).$mount("#app");
