var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),_c('div',{staticClass:"footer mobile"},[_vm._m(1),_c('div',{staticClass:"footer-bottom mx-auto"},[(!_vm.$store.getters.accessToken)?_c('button',{staticClass:"main-button"},[_vm._v(" S’identifier ")]):_vm._e(),_vm._m(2),_vm._m(3)])]),_c('div',{staticClass:"footer container-fluid p-4 desktop"},[_c('div',{staticClass:"row w-100"},[_c('div',{staticClass:"col-6"},[_c('router-link',{attrs:{"to":"/about"}},[_c('div',{staticClass:"nav-item"},[_vm._v("À propos")])])],1),_c('div',{staticClass:"col-6"},[_c('router-link',{attrs:{"to":"/privacy-policy"}},[_c('div',{staticClass:"nav-item"},[_vm._v("Politique de confidentialité")])])],1),_c('div',{staticClass:"col-6"},[_c('router-link',{attrs:{"to":"/terms-and-conditions"}},[_c('div',{staticClass:"nav-item"},[_vm._v("Termes et conditions")])])],1),_c('div',{staticClass:"col-6"},[_c('router-link',{attrs:{"to":"/contact"}},[_c('div',{staticClass:"nav-item"},[_vm._v("Nous contacter")])])],1),(!_vm.$store.getters.accessToken)?_c('div',{staticClass:"col-6"},[_c('router-link',{attrs:{"to":"/login"}},[_c('div',{staticClass:"nav-item"},[_vm._v("S’identifier")])])],1):_vm._e(),_vm._m(4)]),_vm._m(5)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container py-5"},[_c('h2',{staticClass:"my-3 text-center"},[_vm._v("Téléchargez notre application")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"d-flex w-100 justify-content-center"},[_c('a',{staticClass:"mx-1",staticStyle:{"width":"250px","max-width":"40%"},attrs:{"target":"_blank","href":"https://apps.apple.com/app/id6443580980"}},[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":"/buttons/apple.png","alt":"Download from AppStore"}})]),_c('a',{staticClass:"mx-1",staticStyle:{"width":"250px","max-width":"40%"},attrs:{"target":"_blank","href":"https://play.google.com/store/apps/details?id=com.edays.io.app"}},[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":"/buttons/google.png","alt":"Download from Play Market"}})])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_c('a',{attrs:{"href":"/about"}},[_vm._v(" À propos ")])]),_c('li',[_c('a',{attrs:{"href":"/pricing"}},[_vm._v(" Formules ")])]),_c('li',[_c('a',{attrs:{"href":"/contact"}},[_vm._v(" Nous contacter ")])]),_c('li',[_c('a',{attrs:{"href":"/privacy-policy"}},[_vm._v(" Politique de confidentialité ")])]),_c('li',[_c('a',{attrs:{"href":"/terms-and-conditions"}},[_vm._v(" Termes et conditions ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footer-logos d-flex justify-content-center align-items-center"},[_c('a',{attrs:{"target":"_blank","href":"https://www.instagram.com/edays_fr/"}},[_c('img',{attrs:{"src":require("../assets/img/footer-logos/instagram.svg"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pb-4"},[_c('a',{attrs:{"href":"mailto:info@edays.io"}},[_vm._v("INFO@EDAYS.IO")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-6"},[_c('div',{staticClass:"footer-logos d-flex justify-content-center align-items-center"},[_c('a',{attrs:{"target":"_blank","href":"https://www.instagram.com/edays_fr/"}},[_c('img',{attrs:{"src":require("../assets/img/footer-logos/instagram.svg"),"alt":""}}),_vm._v(" INSTAGRAM ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footer-right d-flex flex-column justify-content-between"},[_c('img',{attrs:{"src":require("../assets/img/footer-logo-desktop.svg"),"alt":""}}),_c('div',[_c('a',{attrs:{"href":"mailto:info@edays.io"}},[_vm._v("INFO@EDAYS.IO")])])])
}]

export { render, staticRenderFns }