import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import AOS from "aos";

Vue.use(VueRouter);

const routes = [
  {
    path: "/home",
    name: "Home",
    redirect: store.getters.accessToken ? "/profile" : "/",
  },
  {
    path: "/",
    name: "Home",
    component: function () {
      return import("../views/Home.vue");
    },
    meta: { requiresAuth: false },
  },
  {
    path: "/about",
    name: "About",
    meta: { requiresAuth: false },
    component: function () {
      return import(/* webpackChunkName: "about" */ "../views/About.vue");
    },
  },
  {
    path: "/login",
    name: "Login",
    meta: { requiresAuth: false },
    component: function () {
      return import("../views/Login.vue");
    },
  },
  {
    path: "/change-plan",
    name: "ChangePlan",
    meta: { requiresAuth: true },
    component: function () {
      return import("../views/ChangePlan.vue");
    },
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicy",
    meta: { requiresAuth: false },
    component: function () {
      return import("../views/PrivacyPolicy.vue");
    },
  },
  {
    path: "/terms-and-conditions",
    name: "PrivacyPolicy",
    meta: { requiresAuth: false },
    component: function () {
      return import("../views/TermsAndConditions.vue");
    },
  },
  {
    path: "/contact",
    name: "Contact",
    meta: { requiresAuth: false },
    component: function () {
      return import("../views/Contact.vue");
    },
  },
  {
    path: "/profile",
    name: "Profile",
    meta: { requiresAuth: true },
    component: function () {
      return import("../views/Profile.vue");
    },
  },
  {
    path: "/phrase/:id",
    name: "Phrase",
    meta: { requiresAuth: true },
    component: function () {
      return import("../views/Phrase.vue");
    },
  },
  {
    path: "/edit-profile",
    name: "Edit Profile",
    meta: { requiresAuth: true },
    component: function () {
      return import("../views/EditProfile.vue");
    },
  },
  {
    path: "/pricing",
    name: "Pricing",
    meta: { requiresAuth: false },
    component: function () {
      return import("../views/Pricing.vue");
    },
  },
  {
    path: "/payment",
    name: "PaymentTest",
    meta: { requiresAuth: false },
    component: function () {
      return import("../views/PaymentTest.vue");
    },
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

router.beforeEach((to, from, next) => {
  setTimeout(() => {
    AOS.init({
      duration: 800,
    });
  }, 1000);

  const isLoggedIn = store.state.accessToken;

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!isLoggedIn && to.path == "/" && to.path !== "/profile") {
      next({ path: "/home" });
    }
  }
  if (to.matched.some((record) => !record.meta.requiresAuth)) {
    if (isLoggedIn && to.path == "/profile") {
      next({ path: "/profile" });
    }
  }

  next();
});

export default router;
