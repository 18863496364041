<template>
  <div>
    <div class="header mobile">
      <div class="d-flex px-3 w-100 justify-content-between">
        <div @click="changeTo('/')">
          <img src="../assets/img/Logo.svg" alt="" />
        </div>
        <button
          type="button"
          :class="isOpen ? 'active bg-custom-button' : 'bg-custom-button'"
          @click="isOpen = !isOpen"
        >
          <span class="line"></span>
          <span class="line"></span>
          <span class="line"></span>
        </button>
      </div>
      <Slide
        :class="isOpen ? `opened` : ``"
        :isOpen="isOpen"
        disableOutsideClick
        :burgerIcon="false"
        :crossIcon="false"
        right
      >
        <button
          v-if="$store.state.accessToken"
          class="nav-item main-button"
          @click="changeTo('/profile')"
        >
          <span>Profil</span>
        </button>

        <button class="nav-item main-button" @click="changeTo('/about')">
          <span>À propos</span>
        </button>

        <button class="nav-item main-button" @click="changeTo('/pricing')">
          <span>Formules</span>
        </button>
        <button class="nav-item main-button" @click="changeTo('/contact')">
          <span>Nous contacter</span>
        </button>
      </Slide>
    </div>
    <div class="header desktop">
      <div
        class="d-flex px-3 w-100 container justify-content-around align-items-center"
      >
        <div class="pointer" @click="changeTo('/')">
          <img src="../assets/img/Logo.svg" alt="" />
        </div>
        <div class="d-flex justify-content-around w-100">
          <router-link class="nav-item" to="/about">À propos</router-link>
          <a
            v-if="!$store.state.accessToken"
            class="nav-item"
            href="/home#pricing"
            >Formules</a
          >
          <a v-else class="nav-item" href="/pricing">Formules</a>
          <router-link class="nav-item" to="/contact"
            >Nous contacter</router-link
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Slide } from "vue-burger-menu";

export default {
  components: {
    Slide,
  },
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    logout() {
      this.$router.push("/");
      this.$store.commit("LOGOUT");
      this.isOpen = false;
    },
    changeTo(route) {
      this.$router.push(route);
      this.isOpen = false;
    },
  },

  name: "Navbar",
};
</script>
