<template>
  <div id="app" :class="platform">
    <Navbar />
    <router-view />
    <SubscribeSection />
    <Footer />
  </div>
</template>

<script>
import { Capacitor } from "@capacitor/core";
import Navbar from "../src/layout/Navbar.vue";
import Footer from "../src/layout/Footer.vue";
import SubscribeSection from "../src/components/SubscribeSection.vue";
import AOS from "aos";
import "aos/dist/aos.css";
import { defineCustomElements } from "@stripe-elements/stripe-elements/loader";
defineCustomElements();

export default {
  components: {
    Navbar,
    Footer,
    SubscribeSection,
  },
  data() {
    return {
      isMobile: false,
      platform: "web",
    };
  },
  created() {
    if (window.innerWidth < 769) {
      this.isMobile = true;
    } else this.isMobile = false;

    this.platform = Capacitor.getPlatform();
  },
  mounted() {
    setTimeout(() => {
      AOS.init({
        duration: 800,
      });
    }, 1000);
  },
};
</script>
