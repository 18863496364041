import axios from "axios";
import headers from "./ApiHeaders";
import store from "../store";
// import { dataURLtoFile } from "./helpers/data-url-to-file";

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
});

instance.interceptors.request.use((config) => {
  config.headers = headers();
  return config;
});
instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.data.message === "Unauthorized") {
      store.commit("LOGOUT");
      location.href = "/login";
    }

    throw error;
  }
);

const service = {
  payment: {
    subscribePayment(data) {
      return instance({
        url: "payment/stripe/checkout-session",
        method: "post",
        data,
      });
    },
  },
  media: {
    async upload(file) {
      const formData = new FormData();
      formData.append("type", "Image");
      formData.append("title", "Avatar");
      formData.append("description", "Image for applying TM");
      formData.append("category", "avatar");
      formData.append("file", file);

      const response = await instance({
        url: "media",
        method: "post",
        data: formData,
      });

      return response.data;
    },
  },
  phrase: {
    getCustomerPhrase(id) {
      return instance({
        url: `phrases/customer-phrase/${id}`,
        method: "get",
      });
    },
    ratePhrase(id, rating) {
      return instance({
        url: `phrases/customer-phrase/${id}/rating`,
        method: "patch",
        data: {
          rating,
        },
      });
    },
  },
  account: {
    getProfile() {
      return instance({
        url: "account/customer",
        method: "get",
      });
    },
    getPhrases() {
      return instance({
        url: "account/customer/phrases",
        method: "get",
      });
    },
    changeAvatar(avatarId) {
      return instance({
        url: "account/customer",
        method: "patch",
        data: {
          avatarId,
        },
      });
    },
    updateUser(user) {
      return instance({
        url: "account/customer",
        method: "patch",
        data: user,
      });
    },
  },
  auth: {
    login(data) {
      return instance({
        url: "auth/partner/login",
        method: "post",
        data,
      });
    },
    register(data, id) {
      return instance({
        url: `customer/${id}`,
        method: "patch",
        data,
      });
    },
    sendVerification(phone) {
      return instance({
        url: `auth/customer/send-verification/${phone}`,
        method: "get",
      });
    },
    verify(data) {
      return instance({
        url: "auth/customer/verify",
        method: "post",
        data,
      });
    },
    subscribeDevice(data) {
      return instance({
        url: "account/customer/device-subscription",
        method: "post",
        data,
      });
    },
  },
};
export default service;
