import { register } from "register-service-worker";

class PushAPI {
  constructor(platform = "web") {
    this.platform = platform;
    this.registration = null;
    this.pushSubscription = null;
  }

  askPermission() {
    return new Promise((resolve, reject) => {
      const permissionResult = Notification.requestPermission((result) => {
        resolve(result);
      });
      if (permissionResult) {
        permissionResult.then(resolve, reject);
      }
    }).then((permissionResult) => {
      if (permissionResult !== "granted") {
        throw new Error("Permission denied");
      }
    });
  }

  registerServiceWorker() {
    const that = this;

    return new Promise((resolve, reject) => {
      register("./pushApiServiceWorker.js", {
        registrationOptions: { scope: "./" },
        async ready(registration) {
          that.registration = registration;

          await that.askPermission();

          const options = {
            userVisibleOnly: true,
            applicationServerKey:
              "BEH4yQ_UpqbSfa-xqCQA66L23dOZmwbWVAGmW5FwKJXAgQoeDRJ18EHstXwE8RRvTxHNDH50-qyCbBlUKViHSdI",
          };

          if (!registration.pushManager) reject();

          that.pushSubscription = await registration.pushManager.subscribe(
            options
          );

          resolve(that.pushSubscription);

          console.log("Service worker is active.");
        },
        registered() {
          console.log("Service worker has been registered.");
        },
        cached() {
          console.log("Content has been cached for offline use.");
        },
        updatefound() {
          console.log("New content is downloading.");
        },
        updated() {
          console.log("New content is available; please refresh.");
        },
        offline() {
          console.log(
            "No internet connection found. App is running in offline mode."
          );
        },
        error(error) {
          reject(error);
        },
      });
    });
  }
}

export const pushApi = new PushAPI();
