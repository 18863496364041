<template>
  <div v-if="!$store.state.accessToken" class="subscribe-section">
    <input placeholder="Votre e-mail..." class="mail-input" type="text" />
    <button class="main-button w-100">Adhérez</button>
  </div>
</template>
<script>
export default {
  name: "SubscribeSection",
  data() {
    return {};
  },
};
</script>
